exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-advanced-kiddies-tsx": () => import("./../../../src/pages/advanced-kiddies.tsx" /* webpackChunkName: "component---src-pages-advanced-kiddies-tsx" */),
  "component---src-pages-alphazone-business-tsx": () => import("./../../../src/pages/alphazone-business.tsx" /* webpackChunkName: "component---src-pages-alphazone-business-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internship-tsx": () => import("./../../../src/pages/internship.tsx" /* webpackChunkName: "component---src-pages-internship-tsx" */),
  "component---src-pages-kiddies-bootcamp-tsx": () => import("./../../../src/pages/kiddies-bootcamp.tsx" /* webpackChunkName: "component---src-pages-kiddies-bootcamp-tsx" */),
  "component---src-pages-kids-registration-tsx": () => import("./../../../src/pages/kids-registration.tsx" /* webpackChunkName: "component---src-pages-kids-registration-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-registration-tsx": () => import("./../../../src/pages/registration.tsx" /* webpackChunkName: "component---src-pages-registration-tsx" */),
  "component---src-pages-trainings-tsx": () => import("./../../../src/pages/trainings.tsx" /* webpackChunkName: "component---src-pages-trainings-tsx" */),
  "component---src-pages-workshop-tsx": () => import("./../../../src/pages/workshop.tsx" /* webpackChunkName: "component---src-pages-workshop-tsx" */)
}

